const account = {
  // 获取token
  token: 'account/token',
  // 获取短信验证码
  smsCode: 'account/smsCode',
  // 校验短信验证码
  verifySmsCode: 'account/verifySmsCode',
  // 修改密码
  resetPassword: 'account/resetPassword',
  // 注册账号
  register: 'account/register',
  // 获取用户信息
  info: 'user/info',
  // 获取用户信息
  logout: 'account/logout',
  // 检测手机号
  mobileExistence: 'account/mobileExistence'
}
const user = {
  // 创建项目
  createOrganization: 'user/createOrganization',
  // 解散项目
  dissolve: 'user/dissolve',
  // 是否同意申请加入
  approveInvite: 'user/approveInvite',
  // 更新用户信息
  updateInfo: 'user/updateInfo'
}
const dataCation = {
  // 获取行业信息列表
  listIndustrialClassification: 'data/listIndustrialClassification'
}
const organization = {
  // 修改项目信息
  editOrganization: 'organization/editOrganization',
  // 部门列表
  listDepartment: 'organization/listDepartment',
  // 成员列表
  listMember: 'organization/listMember',
  // 部门列表 含子成员
  listDepartmentMember: 'organization/listDepartmentMember',
  // 修改部门名称
  editDepartment: 'organization/editDepartment',
  // 删除部门
  deleteDepartment: 'organization/deleteDepartment',
  // 创建部门
  createDepartment: 'organization/createDepartment',
  // 修改成员的角色
  memberRoleManage: 'organization/memberRoleManage',
  // 修改成员所属的部门
  memberDepartmentManage: 'organization/memberDepartmentManage',
  // 人員審批列表
  listMemberJoin: 'organization/listMemberJoin',
  // 删除部门成员
  deleteMember: 'organization/deleteMember',
  // 搜索用户
  searchUser: 'organization/searchUser',
  // 邀请用户加入群聊
  inviteToJoin: 'organization/inviteToJoin',
  // 设置项目的配置
  configure: 'organization/configure',
  // 撤回用户加入群聊
  revokeInvite: 'organization/revokeInvite',
  // 审批用户加入群聊
  approveApply: 'organization/approveApply'
}
const statistic = {
  memberDailyReport: 'statistic/memberDailyReport',
  organizationDailyReport: 'statistic/organizationDailyReport',
  // 巡检数前三的用户
  patrolTop3: 'statistic/patrolTop3'
}

const patrol = {
  // 巡检成员历史
  patrolHistory: 'patrol/patrolHistory',
  // 点位历史巡查记录
  siteHistory: 'patrol/siteHistory',
  // 最近 项目内所有成员巡检动态
  memberLastPatrol: 'patrol/memberLastPatrol',
  // 点位历史巡查记录
  history: 'patrol/history',
  // 巡检日志详情
  siteLogInfo: 'patrol/siteLogInfo',
  // 巡检记录
  export: 'patrol/export',
  // 创建巡检记录并以电子邮箱发出
  exportWithMediaAndEmail: 'patrol/exportWithMediaAndEmail',
  // 导出巡检月报
  exportOrganizationMonthlyReport: 'patrol/exportOrganizationMonthlyReport'
}
const site = {
  // 点位分组列表
  listGroupSite: 'site/listGroupSite',
  // 修改点位信息
  editSite: 'site/editSite',
  // 删除点位
  deleteSite: 'site/deleteSite',
  // 创建点位
  createSite: 'site/createSite',
  // 点位详情
  siteInfo: 'site/siteInfo',
  // 修改点位分组名称
  editGroup: 'site/editGroup',
  // 删除点位分组
  deleteGroup: 'site/deleteGroup',
  // 添加点位分组
  createGroup: 'site/createGroup',
  // 巡检项归类列表（包含子级：巡检项）
  listCategoryInspection: 'site/listCategoryInspection',
  // 点位列表
  listSite: 'site/listSite',
  // 修改分类名称
  editCategory: 'site/editCategory',
  // 教程详情
  tutorialInfo: 'site/tutorialInfo',
  // 修改巡检项
  editInspection: 'site/editInspection',
  // 删除巡检项
  deleteInspection: 'site/deleteInspection',
  // 添加分类
  createCategory: 'site/createCategory',
  // 添加巡检项
  createInspection: 'site/createInspection',
  // 删除巡检项
  deleteCategory: 'site/deleteCategory',
  // 获取本项目 试用版的二维码 下载链接
  trialQrcodeZipLink: 'site/trialQrcodeZipLink'
}
const task = {
  // 任务列表
  listTask: 'task/listTask',
  // 评价列表
  listOperation: 'task/listOperation',
  // 任务详情
  taskInfo: 'task/taskInfo',
  // 集体任务列表
  listBoard: 'task/listBoard',
  // 集体任务详情
  boardInfo: 'task/boardInfo',
  // 周期任务列表
  listPlan: 'task/listPlan',
  // 周期任务详情
  planInfo: 'task/planInfo',
  // 工单列表
  listProblem: 'problem/listProblem',
  // 创建任务
  createTask: 'task/createTask',
  // 创建集体任务
  createBoard: 'task/createBoard',
  // 创建周期任务
  createPlan: 'task/createPlan'
}
const problem = {
  // 工单列表
  listProblem: 'problem/listProblem',
  // 异常详情
  problemInfo: 'problem/problemInfo',
  // 操作列表
  listOperation: 'problem/listOperation',
  // 创建工单
  createProblem: 'problem/createProblem'

}
const files = {
  huaweiCloudObsPolicySignature: 'file/huaweiCloudObsPolicySignature'
}

// [OPTION]
// 正式版域名
// const domain = 'bat.i-patrol.cn'
// 测试版域名
const domain = 'dev.i-patrol.cn'

// [OPTION]
// 正式版OBS地址
// const obsUrl = 'http://obs.i-patrol.cn'
// 测试版OBS地址
const obsUrl = 'http://obs-test.i-patrol.cn'

// 服务器地址
const envUrl = 'https://' + domain + '/'

// 接口版本
const apiVersion = 'v1'

// 发送地址请求
const bsURl = envUrl + apiVersion + '/web'

// 图片上传地址（实际为文件下载地址）
const uploadUrl = 'https://' + domain

const order = {
  listProduct: 'order/listProduct',
  renew: 'order/renew',
  // 订单列表
  listOrder: 'order/listOrder',
  // 取消订单
  cancelOrder: 'order/cancelOrder',
  // 开票
  invoice: 'order/invoice',
  // 银行对公转账
  payBank: 'order/payBank',
  // 看看付钱了没有
  payQuery: 'order/payQuery',
  // 微信支付
  payWechat: 'order/payWechat',
  // 支付宝支付
  payAlipay: 'order/payAlipay',
  // 创建硬件订单
  hardware: 'order/hardware',
  // 创建硬件订单
  upgrade: 'order/upgrade'
}
export {
  account,
  user,
  dataCation,
  organization,
  statistic,
  patrol,
  site,
  task,
  problem,
  files,
  uploadUrl,
  bsURl,
  envUrl,
  obsUrl,
  order
}
