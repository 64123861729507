export const path = [
  "account/smsCode",
  "account/register",
  "account/resetPassword",
  "account/token",
  "account/logout",
  "message/readAllMessage",
  "message/deleteMessage",
  "organization/updateMemberInfo",
  "organization/editOrganization",
  "organization/inviteToJoin",
  "organization/revokeInvite",
  "organization/approveApply",
  "organization/createDepartment",
  "organization/editDepartment",
  "organization/deleteDepartment",
  "organization/memberRoleManage",
  "organization/memberDepartmentManage",
  "organization/deleteMember",
  "organization/configure",
  "patrol/export",
  "patrol/exportWithMediaAndEmail",
  "patrol/exportOrganizationMonthlyReport",
  "problem/createProblem",
  "problem/problemInfo",
  "problem/comment",
  "problem/revokeComment",
  "problem/transfer",
  "problem/setStatus",
  "problem/draw",
  "problem/dispatch",
  "problem/unhandledProblemCount",
  "site/createGroup",
  "site/editGroup",
  "site/deleteGroup",
  "site/createSite",
  "site/editSite",
  "site/editSiteInfo",
  "site/editSiteInspections",
  "site/deleteSite",
  "site/createPath",
  "site/editPath",
  "site/deletePath",
  "site/createCategory",
  "site/editCategory",
  "site/deleteCategory",
  "site/createInspection",
  "site/editInspection",
  "site/deleteInspection",
  "site/siteGroupManage",
  "site/inspectionCategoryManage",
  "site/qrcodeStatus",
  "site/trialQrcodeFileLink",
  "task/createTask",
  "task/editTask",
  "task/deleteTask",
  "task/createBoard",
  "task/editBoard",
  "task/deleteBoard",
  "task/createPlan",
  "task/editPlan",
  "task/deletePlan",
  "task/comment",
  "task/revokeComment",
  "user/updateInfo",
  "user/changePassword",
  "user/createOrganization",
  "user/feedback",
  "user/dissolve",
  "order/renew",
  "order/invoice",
  "order/hardware",
  "account/verifySmsCode",
  "order/upgrade"
];
