import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/register.vue")
  },
  {
    path: "/newRegister",
    name: "newRegister",
    component: () => import("../views/newRegister/newRegister.vue")
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("../views/forgetPassword/forgetPassword.vue")
  },
  // 项目信息页面
  {
    path: "/projectInfo",
    name: "projectInfo",
    redirect: "/homePage",
    component: () => import("@/views/projectInfo/projectInfo.vue"),
    children: [
      {
        // 首页
        path: "/homePage",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/homePage/homePage.vue")
      },
      {
        // 巡检
        path: "/patrol",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/patrol/patrol.vue")
      },
      {
        // 任务
        path: "/assignment",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/assignment/assignment.vue")
      },
      {
        // 工单
        path: "/order",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/order/order.vue")
      },
      {
        // 点位
        path: "/location",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/location/location.vue")
      },
      {
        // 人员
        path: "/personnel",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/personnel/personnel.vue")
      },
      {
        // 新建项目
        path: "/newProject",
        name: "newProject",
        component: () => import("@/views/newProject/newProject.vue")
      },
      {
        // 项目信息
        path: "/projectDetail",
        component: () => import("@/views/projectDetail/projectDetail.vue")
      },
      {
        // 项目配置
        path: "/setProject",
        component: () => import("@/views/setProject/setProject.vue")
      },
      {
        // 巡检项目管理
        path: "/manageProject",
        component: () => import("@/views/manageProject/manageProject.vue")
      },
      {
        // 添加任务
        path: "/addTask",
        component: () => import("@/views/addTask/addTask.vue")
      },
      {
        // 添加集体任务
        path: "/addCollectiveTask",
        component: () =>
          import("@/views/addCollectiveTask/addCollectiveTask.vue")
      },
      {
        // 添加周期任务
        path: "/addCycleTask",
        component: () => import("@/views/addCycleTask/addCycleTask.vue")
      },
      {
        // 集体任务
        path: "/collectiveTask",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/collectiveTask/collectiveTask.vue")
      },
      {
        // 周期任务
        path: "/cycleTask",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/cycleTask/cycleTask.vue")
      },
      {
        // 个人信息
        path: "/userDetail",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/userDetail/userDetail.vue")
      },
      {
        // 订单
        path: "/indent",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/indent/indent.vue")
      },
      {
        // 新工单
        path: "/newOrder",
        component: () => import("@/views/newOrder/newOrder.vue")
      },
      {
        // 续费
        path: "/extension",
        component: () => import("@/views/extension/extension.vue")
      },
      {
        // 升级
        path: "/upgrade",
        component: () => import("@/views/upgrade/upgrade.vue")
      },
      {
        // 支付
        path: "/pay",
        name: "pay",
        component: () => import("@/views/pay/pay.vue")
      },
      {
        // 订单列表
        path: "/orderlist",
        component: () => import("@/views/orderlist/orderlist.vue")
      },
      {
        // 硬件购买
        path: "/payHardWare",
        component: () => import("@/views/payHardWare/payHardWare.vue")
      }
    ]
  }
];

const router = new VueRouter({
  // mode: 'history',
  routes
});
router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem("token");
  if (!token) {
    if (
      to.name == "forgetPassword" ||
      to.name == "register" ||
      to.name == "login"
    ) {
      return next();
    } else {
      return next("/login");
    }
  }else {
    next();
  }
});
export default router;
