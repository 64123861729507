import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 用来存储用户信息
        user_info: {},
        // 存放当前项目
        current_project: {
            organization: {
                service_expire: 0
            }
        },
        // 存放下拉框数组信息
        projectArr: [],
        // 下拉框的默认值
        projectValue: ''
    },
    mutations: {
        setUserInfo(state, info) {
            state.user_info = info
        },
        setCurrentProject(state, project) {
            state.current_project = project
        },
        setProjectArr(state, arr) {
            state.projectArr = arr
        },
        setprojectValue(state, value) {
            state.projectValue = value
        },
        // 设置项目配置
        setConfigValue(state, value) {
            // state.projectValue = value
            state.current_project.organization.configurations.forEach(item => {
                if (item.key == value.key) {
                    item.value = value.value
                }
            })
            state.user_info.members.forEach(item => {
                if (item.organization_id == state.projectValue) {
                    item = state.current_project
                }
            })
        },
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState({
      storage: window.sessionStorage
  })]
})
