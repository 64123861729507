<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  methods:{
    errorHandler() {
        return true
      }
  }
};
</script>
<style lang="scss">
.flex_center_col {
  display: flex;
  align-items: center;
}
.flex_center_col_100 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_center_bew {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center_center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-cascader-menu {
  height: 300px !important;
}
.queryBtn {
  background-color: #f56c6c !important;
  border: none !important;
}
.flex {
  display: flex;
}
.ivu-page-simple .ivu-page-simple-pager input {
  padding: 0;
}
.active_color {
  background-color: #f5f7fa !important;
}
.border_5 {
  border-radius: 5px;
}
.flex_clo {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.el-tree {
  border-radius: 5px;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  max-width: 116px;
  min-width: 116px!important;
}

</style>
