import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugin/plugin";
import axios from "axios";
import { Message, MessageBox } from "element-ui";
import "normalize.css/normalize.css";
import moment from "moment";
// import QRCode from 'qrcodejs2'
import "view-design/dist/styles/iview.css";
import _ from "lodash";
import ViewUI from "view-design";
import { bsURl } from "./utils/api";
import { path } from "./utils/path";
Vue.prototype._ =_;

Vue.filter("dateformat", function(dataStr, pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dataStr).format(pattern);
});
// 配置axios请求
axios.defaults.baseURL = bsURl;
axios.defaults.headers.post["Content-Type"] = "application/json";
// 添加一个请求拦截器
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = window.sessionStorage.getItem("token")
      ? window.sessionStorage.getItem("token")
      : "";
    const id = store.state.current_project.organization_id
      ? store.state.current_project.organization_id
      : "";
    config.headers["token"] = token;
    config.headers["current-organization-id"] = id;
    ViewUI.LoadingBar.start();
    return config;
  },
  error => {
    return Promise.error(error);
  }
);
// 配置响应拦截器
axios.interceptors.response.use(
  res => {
    if (res.data.code != 0) {
      if (+res.data.code === 10002) {
        MessageBox.alert("你已被登出", "注意", {
          confirmButtonText: "确定",
          callback: () => {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("vuex");
            sessionStorage.removeItem("index");
            window.location.reload();
            router.push("login");
          }
        });
      }
      // else if(res.config.url == "user/createOrganization"){
      //   return
      // }
      else if (res.config.method == "post") {
        if (path.find(item => item == res.config.url)) {
          Message.error(res.data.message);
        } else {
          Message.error("获取失败");
        }
      } else {
        if (res.config.url.indexOf("?") > -1) {
          if (
            path.find(
              item =>
                item == res.config.url.slice(0, res.config.url.indexOf("?"))
            )
          ) {
            Message.error(res.data.message);
          } else {
            Message.error("获取失败");
          }
        } else {
          if (path.find(item => item == res.config.url)) {
            Message.error(res.data.message);
          } else {
            Message.error("获取失败");
          }
        }
      }
      // Message({ message: res.data.message, type: 'error' })
    } else {
      // Message({ message: res.data.message, type: 'success' })
    }
    ViewUI.LoadingBar.finish();
    return Promise.resolve(res.data);
  },
  error => {}
);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
